<template>
  <div class="prompts-container">
    <div class="title page-title">发现最好用的Chat AI提示词</div>
    <div class="title page-sub-title">最佳的Chat AI提示词集合,按类别、专业或用例查找最佳的Chat AI提示</div>
    <div class="title page-search">
      <div class=" input-box mb20">
        <a-input class="input" ref="codeInput" v-model="text" @keydown.native="handleKeyCode($event)"/>
        <span class="span" @click="search">搜</span>
      </div>
    </div>
    <a-modal v-model="showPanel"
             :footer="null"
             :width="600"
             :wrapStyle="{ overflow: 'hidden', top: '-60px' }">
      <template slot="title">
        搜索提示词
      </template>
      <PromptsPanel :searchText.sync="text" :panelHeight="panelHeight" @clickPrompt="clickPrompt"/>
    </a-modal>
    <!--    <div v-if="showDetails">-->
    <!--      <PromptsDetails/>-->
    <!--    </div>-->
  </div>
</template>

<script>
export default ({
  components: {
    PromptsPanel: () => import('@/views/application/chat/components/PromptsPanel.vue'),
    PromptsDetails: () => import('@/views/application/chat/components/PromptsDetails.vue')
  },
  computed: {
    panelHeight: {
      get() {
        return window.innerHeight - 215
      }
    }
  },
  data() {
    return {
      text: '',
      showPanel: false,
      showDetails: false
    }
  },
  methods: {
    clickPrompt(curPrompt) {
      this.showPanel = false
      this.showDetails = true
      this.$router.push({name: '发现提示词'})

      console.log("curPrompt===", curPrompt)
    },
    search() {
      this.showPanel = true
    },
    handleKeyCode(event) {
      if (event.keyCode === 13) {
        if (!event.ctrlKey) {
          event.preventDefault();
          this.search()
        }
      }
    },
  },
  mounted() {
    this.$refs.codeInput.focus()
  }
})
</script>

<style scoped lang="scss">
.prompts-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 200px);

  .title {
    margin-top: 15px;
  }

  .page-title {
    text-align: center;
    font-size: 36px;
    font-weight: 600;
  }

  .page-sub-title {
    margin-top: 20px;
    font-size: 16px;
  }

  .page-search {
    width: 600px;
    margin-top: 30px;

    .input-box {
      position: relative;
      display: inline-block;
    }

    .input {
      padding: 0 40px 0 20px;
      width: 600px;
      height: 38px;
      font-size: 14px;
      border: 1px solid transparent;
      border-radius: 40px;
      //background: #eee;
      transition: width .5s;
      transition-delay: .1s;
    }

    .span {
      position: absolute;
      top: 4px;
      right: 5px;
      width: 30px;
      height: 30px;
      line-height: 30px;
      padding: 0;
      color: #D9D9D9;
      text-align: center;
      background: #536976;
      border-radius: 50%;
      font-size: 15px;
      cursor: pointer;
    }
  }

  .input:focus {
    width: 600px;
    outline: none;
    box-shadow: none;
    border: 1px solid #536976;
  }

  .input:focus + .span {
    background-color: pink;
    color: #fff;
  }

}
</style>